<template>
  <div>
    <Card :bordered="false" dis-hover class="ivu-mt">
      <Form
        ref="levelFrom"
        :model="levelFrom"
        :label-width="80"
        label-position="right"
        @submit.native.prevent>
        <Row type="flex" :gutter="24">
          <Col v-bind="grid">
            <FormItem label="状态：" label-for="status1">
              <Select
                v-model="levelFrom.is_show"
                placeholder="请选择"
                clearable
                element-id="status1"
                @on-change="handleSearchs">
                <Option value="1">显示</Option>
                <Option value="0">不显示</Option>
              </Select>
            </FormItem>
          </Col>
          <Col v-bind="grid">
            <FormItem label="等级名称：" label-for="title">
              <Input search enter-button v-model="levelFrom.title" placeholder="请输入等级名称" @on-search="handleSearchs"/>
            </FormItem>
          </Col>
        </Row>
        <Row type="flex">
          <Col v-bind="grid">
            <Button v-auths="[`${$config.uniquePrefix}user:level:create`]" type="primary" icon="md-add" @click="handleCreate">添加用户等级</Button>
          </Col>
        </Row>
      </Form>
      <Table
        :columns="columns"
        :data="levelList"
        ref="table"
        class="l-m-t25"
        :loading="loading"
        highlight-row
        no-userFrom-text="暂无数据"
        no-filtered-userFrom-text="暂无筛选结果">
        <template slot-scope="{ row }" slot="level_icons">
          <div class="tabBox_img" v-viewer>
            <img v-lazy="row.icon" />
          </div>
        </template>
        <template slot-scope="{ row }" slot="icons">
          <div class="tabBox_img" v-viewer>
            <img v-lazy="row.image" />
          </div>
        </template>
        <template slot-scope="{ row }" slot="is_forevers">
          <i-switch
            v-model="row.is_forever"
            :value="row.is_forever"
            :true-value="1"
            :false-value="0"
            :disabled="true"
            size="large">
            <span slot="open">永久</span>
            <span slot="close">非永久</span>
          </i-switch>
        </template>
        <template slot-scope="{ row }" slot="is_pays">
          <i-switch
            v-model="row.is_pay"
            :value="row.is_pay"
            :true-value="1"
            :false-value="0"
            :disabled="true"
            size="large">
            <span slot="open">付费</span>
            <span slot="close">免费</span>
          </i-switch>
        </template>
        <template slot-scope="{ row }" slot="is_shows">
          <i-switch
            v-model="row.is_show"
            :value="row.is_show"
            :true-value="1"
            :false-value="0"
            @on-change="onchangeIsShow(row)"
            size="large">
            <span slot="open">显示</span>
            <span slot="close">隐藏</span>
          </i-switch>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <template>
            <Dropdown @on-click="handleChangeMenu(row, $event, index)" :transfer="true">
              <a href="javascript:void(0)">
                更多<Icon type="ios-arrow-down"></Icon>
              </a>
              <DropdownMenu slot="list">
                <DropdownItem name="1">等级任务</DropdownItem>
                <DropdownItem name="3">编辑等级</DropdownItem>
                <DropdownItem class="l-color-error" name="2" divided>删除等级</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </template>
        </template>
      </Table>
      <div class="acea-row row-right l-m-t25">
        <Page
          :total="total"
          :current="levelFrom.page"
          show-elevator
          show-total
          @on-change="handlePageChange"
          :page-size="levelFrom.limit"/>
      </div>
    </Card>
  </div>
</template>

<script>
import mixins from '@/mixins'
import {
  getLeveListApi
} from '@/api/users'

export default {
  name: 'index',
  mixins: [mixins],
  data () {
    return {
      levelFrom: {
        is_show: '',
        title: '',
        page: 1,
        limit: 15
      },
      // 表格数据
      columns: [
        {
          title: 'ID',
          key: 'id',
          width: 80,
          align: 'center'
        },
        {
          title: '等级图标',
          slot: 'level_icons',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '等级背景图',
          slot: 'icons',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '等级名称',
          key: 'name',
          minWidth: 120,
          align: 'center'
        },
        {
          title: '等级',
          key: 'grade',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '享受折扣',
          key: 'discount',
          minWidth: 100,
          align: 'center'
        },
        {
          title: '经验值要求',
          key: 'exp_num',
          minWidth: 100,
          align: 'center'
        },
        // {
        //     title: '有效时间',
        //     key: 'valid_date',
        //     minWidth: 120
        // },
        // {
        //     title: '是否永久',
        //     slot: 'is_forevers',
        //     minWidth: 130
        // },
        // {
        //     title: '是否付费',
        //     slot: 'is_pays',
        //     minWidth: 120
        // },
        {
          title: '是否显示',
          slot: 'is_shows',
          minWidth: 80,
          align: 'center'
        },
        // {
        //   title: '等级说明',
        //   key: 'explain',
        //   minWidth: 120,
        // },
        {
          title: '操作',
          slot: 'action',
          fixed: 'right',
          minWidth: 80,
          align: 'center'
        }
      ],
      levelList: []
    }
  },
  created () {
    this.getLeveListRequest()
  },
  methods: {
    // 获取用户等级列表
    getLeveListRequest () {
      this.loading = true
      getLeveListApi().then(res => {
        let data = res.data
        this.levelList = data.list
        this.total = res.data.count
        this.loading = false
      }).catch(err => {
        this.loading = false
        this.$Message.error(err.msg)
      })
    },
    // 处理下拉菜单
    handleChangeMenu () {},
    // 创建用户等级
    handleCreate () {},
    // 处理搜索事件
    handleSearchs () {},
    // 处理分页
    handlePageChange () {}
  }
}
</script>

<style scoped lang="scss">
.tabBox_img {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
